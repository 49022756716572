<template>
  <!-- 续费 -->
  <div>
    <el-dialog
      title="延期"
      :visible.sync="isOpen"
      :close-on-click-modal="false"
      width="900px"
      @closed="closeModal">
      <div class="modal-dialog-body">
        <div class="container">
          <div class="title">延期时间：</div>
          <div>
            <input :class="['button',year === 1? 'blue':'']" type="button" value="一年" @click="getYear(1)"/>
            <input :class="['button',year === 2? 'blue':'']" type="button" value="二年" @click="getYear(2)"/>
            <input :class="['button',year === 3? 'blue':'']" type="button" value="三年" @click="getYear(3)"/>
          </div>
        </div>
        <div class="row">
          <span class="title-text">产品类型：</span>
          <div class="table-wrapper">
            <ch-table :render-option="tableOption" :data="tableList"
                      :props="{width: '100%', minHeight: '200px', size: 'mini'}"
                      :loading="isTableLoading"
            ></ch-table>
          </div>
        </div>
        <div>
          <span class="title-text">费用合计：</span>
          <span class="cost-text">￥ {{totalAmount}}</span>
        </div>
        <div class="payType">
          <div class="select-card-title">支付方式：</div>

          <div
              class="select-card-box"
              :class="{ 'active-select': payType === 0 }"
              @click="getPay(0)"
          >
            <div class="card-box">
              <div class="left-img">
                <img style="width: 100%" src="../../../assets/img/pay/yinlian.png" alt />
              </div>
              <div class="left-tip">
                <div>对公转账</div>
                <div class="eng-lang">Public Transfer</div>
              </div>
            </div>
            <div class="bottom-tip">对公转账</div>
          </div>

          <div
              class="select-card-box"
              :class="{ 'active-select': payType === 2 }"
              @click="getPay(2)"
          >
            <div class="card-box weixin">
              <div class="left-img">
                <img style="width: 100%" src="../../../assets/img/pay/weixin.png" alt />
              </div>
              <div class="left-tip">
                <div>微信支付</div>
                <div class="eng-lang">WeChat pay</div>
              </div>
            </div>
            <div class="bottom-tip">微信支付</div>
          </div>

          <div
              class="select-card-box"
              :class="{ 'active-select': payType === 4 }"
              @click="getPay(4)"
          >
            <div class="card-box">
              <div class="left-img">
                <img style="width: 30px; height: 36px" src="../../../assets/img/pay/company.png" alt />
              </div>
              <div class="left-tip">
                <div>余额支付 ￥{{cashBalance}}</div>
                <div class="eng-lang">Balance Pay</div>
              </div>
            </div>
            <div class="bottom-tip">余额支付</div>
          </div>
        </div>
      </div>
      <div slot="footer" class="flex_con">
        <ch-button type="cancel"  @click="closeModal">取消</ch-button>
        <ch-button type="ok" :loading="isBtnLoading" :disabled="disabled" @click="handleModal">支付</ch-button>
      </div>
    </el-dialog>
    <renewal-pay ref="pay" :getListFunction="getRenewInfo" />
  </div>
</template>

<script>
import renewalPay from "./renewalPay";
import dayjs from "dayjs";

export default {
  props: {
    getListFunction: {
      type: Function
    }
  },
  components: { renewalPay },
  data(){
    return {
      tableList: [],
      typeObj: {
        0:'操作人数',
        1:'社区数',
        2:'基础套餐',
        3:'短信',
      },
      rules: {
        number: [{ required: true, message: '请选择购买时长', trigger: 'change' }],
        payWay: [{ required: true, message: '请选择支付方式', trigger: 'change' }],
      },
      isBtnLoading: false,
      isTableLoading: false,
      disabled: true,
      isOpen: false,
      year: 1,
      payType:null,
      total:null,
      totalAmount:null, // 费用合计
      cashBalance:null, // 余额
      isPay: null, //余额支付是否成功
    }
  },
  computed: {
    tableOption: function () {
      return [
        {column: 'text', label: '签约项目：', prop: 'name', showOverflowTooltip: true },
        {column: 'text', label: '服务类型', prop: '_type', showOverflowTooltip: true},
        {column: 'text', label: '元/年', prop: 'annualFee', showOverflowTooltip: true},
        {column: 'text', label: '到期日期', prop: 'serverEndTime', showOverflowTooltip: true},
        {column: 'text', label: '延期日期', prop: 'postponeTime', showOverflowTooltip: true},
      ]
    }
  },
  methods: {
    // 获取焦点
    getYear(year = this.year){
      this.year = year
      this.totalAmount = (this.total*100)*this.year/100
      this.tableList.forEach(item => {
        item.postponeTime = dayjs(item.serverEndTime).add(365*this.year,'day').format('YYYY-MM-DD HH:mm:ss')
      })
    },
    getPay(pay){
      if(this.cashBalance<=this.totalAmount && pay ===4){
        this.$message({
          type: "error",
          message: "余额不足!",
        });
        return
      }
      this.payType = pay
      this.disabled = false
    },


    open(row) {
      this.isTableLoading = true
      this.userId = row.userId
      this.isOpen = true
      this.getRenewInfo()
    },
    //
    async getRenewInfo(){
      await this.$curl.get('/client/sysCliServerDetail/renewInfo').then(res => {
        this.tableList = res.data.server
        this.total = res.data.total
        this.totalAmount = this.total * this.year
        this.cashBalance = res.data.cashBalance
        this.tableList.forEach(item => {
          if (item.type === 0 || item.type === 1){
            item.name=item.name+"("+item.quantity+")";
          }
          item._type = this.typeObj[item.type] || '一'
          const postponeTime = dayjs(item.serverEndTime).add(366,'day').format('YYYY-MM-DD HH:mm:ss')
          this.$set(item,'postponeTime',postponeTime)
        })
      })
      this.getYear()
      this.isTableLoading = false
    },

    handleModal() {
      let params
      if(this.payType===0){
        params = {
          payType:this.payType,
          year:this.year,
          title:'对公转账',
          totalAmount:this.totalAmount

        }
      }else if(this.payType===2){
        params = {
          payType:this.payType,
          year:this.year,
          title:'微信支付',
          totalAmount:this.totalAmount
        }
      }else if(this.payType===4){
        this.getBalance()
        return;
      }
      this.$refs.pay.openModal(params)
    },
    // 余额支付
    getBalance(){
      this.$curl.post(`/client/sysCliServerDetail/renewPay/${this.year}/${this.payType}`).then(res=> {
        this.cashBalance = res.data.cashBalance
        this.isPay = res.data.isPay
        const params = {
          payType:this.payType,
          year:this.year,
          title:'余额支付',
          totalAmount:this.totalAmount,
          isPay:this.isPay
        }
        this.$refs.pay.openModal(params)
      })
    },

    // 关闭前清除数据
    closeModal() {
      this.id = ''
      this.totalAmount = null
      this.isOpen = false
      this.isBtnLoading = false
      this.title= ''
      this.payType= null
      this.year= 1
      this.disabled = true
    },

  }
}
</script>

<style lang="scss" scoped>
.modal-dialog-body {
  width: 850px;
  display: flex;
  flex-direction: column;
  max-height: 500px;
  padding: 0 20px;
  overflow: scroll;
  .container{
    display: flex;
    align-items: center;
    padding: 10px 0;
    .title{
      padding-right: 20px;
      font-weight: bold;
      color: #000;
      .qrCode{
        text-align: center;
        padding-left: 65px;
        padding-top: 10px;
        .tips{
          color: #333333;
          font-weight: normal;
          font-size: 12px;
        }
      }
    }
    span{
      color: #FB3A39;
      font-weight: bold;
      font-size: 16px;
    }
    #text{
      color: #333333;
      font-weight: normal;
    }
    p{
      color: #333333;
    }
    .code{
      padding-left: 10px;
      color: #3e7eff;
      font-size: 12px;
      cursor: pointer;
      opacity: 0.7;
    }

    .button {
      width: 80px;
      margin-right: 15px;
      padding: 7px 15px;
      cursor: pointer;
      font-size: 12px;
      border-radius: 4px;
      color: #3e7eff;
      border: 1px solid #3e7eff;
      background-color: #ecf5ff;
      &:hover {
        color: #fff;
        background: #3e7eff;
        border: 1px solid #3e7eff;
      }
    }
    .blue{
      color: #fff;
      background: #3e7eff;
      border: 1px solid #3e7eff;
    }
  }
  .tip{
    color: #666666;
    padding-left: 75px;
    font-size: 12px;
  }
}

.modal-dialog-body {
  width: 750px;
  display: flex;
  flex-direction: column;
  .row {
    padding-top: 20px;
  }
  .title-text {
    font-weight: 700;
    color: #000;
  }
  .first-text {
    padding: 0 12px;
  }
  .cost-text {
    font-size: 16px;
    padding: 0 12px;
    color: red;
  }
  .table-wrapper {
    margin: 15px 0;
    border-top: 1px solid #EEEEEE;
    border-left: 1px solid #EEEEEE;
    ::v-deep .el-table__cell {
      border-bottom: 1px solid #EEEEEE;
      border-right: 1px solid #EEEEEE;
    }
  }
.payType{
  display: flex;
  padding-top: 20px;
  font-weight: bold;
  .select-card {
    display: flex;
    justify-content: flex-start;
    margin: 18px 0 43px;
  }
  .select-card-title {
    display: flex;
    font-weight: bold;
    color: #000000;
    align-items: center;
  }
  .select-card-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #a9b9d9;
    border-radius: 5px;
    width: auto;
    margin: 0 1pt 0 10px;
    cursor: pointer;
  }
  .active-select {
    border: 1px solid #3e7eff;
    cursor: pointer;
  }
  .card-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 15px;
    .left-tip{
      padding-left: 10px;
    }
  }
  .bottom-tip {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 0;
    font-size: 12px;
    color: #333333;
    font-weight: 500;
  }
}

  ::v-deep .el-form-item__label {
    font-weight: 700;
  }
}
</style>