<template>
  <el-dialog
      :title="title"
      :visible.sync="isOpenModal"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      width="700px"
      @close="closeModal"
      >
      <div v-if="payType===0">
        <div class="container">
          <div class="title">打款金额</div>
          <span>￥{{(year*totalAmount).toFixed(2)}}</span>
        </div>
        <div class="container">
          <div class="title">收款账号</div>
          <div>
            <span id="text">693496253</span>
          </div>
          <span class="code" @click="clickCopy('#text')">复制</span>
        </div>
        <div class="container">
          <div class="title">单位名称</div>
          <p>广州市彩泓信息科技有限公司</p>
        </div>
        <div class="container">
          <div class="title">开户银行</div>
          <p>中国民生银行广州黄埔大道支行</p>
        </div>
        <div class="tip">对公转账支付后，请将转账页面截图发给您的邻邻發客服，由邻邻發客服后台操作延期，谢谢!</div>
    </div>
      <div v-else-if="payType===2">
      <div class="container center">
        <div class="title">应付金额<span>￥{{(year*totalAmount).toFixed(2)}}</span>
          <div class="flex_con_column"
               v-if="type === 'code'">
            <vue-qr  :text="codeUrl" :size="150" :margin="10"></vue-qr>
            <div class="tips">手机微信,扫一扫付款</div>
          </div>
          <div class="flex_con"
               style="height: 171px"
               v-if="type === 'loading'">
            <div v-loading="true" style="width: 42px; height: 42px"></div>
          </div>
        </div>
      </div>
    </div>
      <div class="dialog-result-box" v-if="payType===4">
        <div v-if="isPay" class="succ-box">
          <ch-icon name="sucess"></ch-icon>
          <div class="pay-sucess-tip">支付成功</div>
        </div>
        <div v-else class="succ-box">
          <ch-icon name="fail"></ch-icon>
          <div class="pay-sucess-tip">支付失败</div>
        </div>
      </div>
  </el-dialog>
</template>

<script>
import vueQr from "vue-qr";
import {mapMutations} from "vuex";

export default {
  components: { vueQr },
  name: "renewalPay",
  props: {
    getListFunction: {
      type: Function,
    },
  },
  data(){
    return{
      title:'',
      isOpenModal:false,
      payType: null,
      year: 1,
      codeUrl:null,
      type: null,
      totalAmount:null,
      isPay:null,
    }
  },
  watch:{
      renewPay: function (newValue) {
        if (newValue === 'success') {
          this.payType = 4
          this.isPay = true
          this.getListFunction?.();
        }
      },
    },
  methods:{
    ...mapMutations({
      saveRenewPay:'payment/saveRenewPay'
    }),
    openModal(params){
      this.type = 'loading'
      const {payType,year,title,totalAmount,isPay} = params
      this.isOpenModal = true
      this.payType = payType
      this.title = title
      this.year = year
      this.isPay = isPay
      this.totalAmount = Number(totalAmount)
      if(this.payType === 2){
        this.getRenewPay()
        return
      }
      if(this.payType ===4 ){
        this.getListFunction?.();
      }
    },
    getRenewPay(){
      this.saveRenewPay(null) // 初始化
      this.$curl.post(`/client/sysCliServerDetail/renewPay/${this.year}/${this.payType}`).then(res=> {
        this.codeUrl = res.data?.url || null
        this.balancePay = res.data?.balancePay || null
        this.type = 'code'
      })
    },
    //复制
    clickCopy(copyValEleName) {
      window.getSelection().removeAllRanges()
      const copyValEle = document.querySelector(copyValEleName)
      const range = document.createRange()
      range.selectNode(copyValEle)
      window.getSelection().addRange(range)
      document.execCommand('copy')
      this.$message.success('复制成功')
      window.getSelection().removeAllRanges()
    },
    closeModal() {
      this.isOpenModal=false
      this.payType= null
      this.year= 1
      this.codeUrl=null
      this.type= null
      this.totalAmount=null
      this.isPay=null
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  padding: 10px 0;

  .title {
    padding-right: 20px;
    font-weight: bold;
    color: #000;
  }

  span {
    color: #FB3A39;
    font-weight: bold;
    font-size: 16px;
  }

  #text {
    color: #333333;
    font-weight: normal;
  }

  p {
    color: #333333;
  }
  .code{
    padding-left: 10px;
    color: #3e7eff;
    font-size: 12px;
    cursor: pointer;
    opacity: 0.7;
  }
}
.center{
  text-align: center;
  .title{
    padding: 0;
  }
}

.dialog-result-box {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.pay-sucess-tip {
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  margin: 20px auto;
}
.CH_icon {
  font-size: 120px;
  color: #f56c6c;
}
</style>